;(function() {
    window.__NEO_WEBVIEW = (function() {
        const available = !!window.ReactNativeWebView;

        const invoke = function(config) {
            if (available) {
                const data = JSON.stringify(config);
                window.ReactNativeWebView.postMessage(data);
            } else {
                console && console.error && console.error("[NEO] Cannot invoke outside of WebView", config);
            }
        };

        return {
            available,
            invoke,
            navigate: function(target, options) {
                invoke({navigate: {target, options: options || {}}});
            },
            share: function(item) {
                invoke({share: item});
            },
            logout: function() {
                invoke({logout: true});
            },
            title: function(title) {
                invoke({title: title});
            },
            track: function(pathArr) {
                invoke({track: {
                        pageName: pathArr[0],
                        lev1: pathArr[1],
                        lev2: pathArr[2]
                    }});
            }
        };
    })();

    //if (__NEO_WEBVIEW.available) {
    //  __NEO_WEBVIEW.title("#{I18n.t("app.app_title")}");
    //}
})();
